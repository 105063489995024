<template>
  <div>
    <div v-if="orginal === 1" class="page_bg">
      <van-image width="8.21rem" height="7.84rem" style="margin-top:1rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/order/shiplistNotWork.png"
      />
      <div class="name">{{ type === 1 ? '失效货单':'打开失败' }}</div>
      <div class="tip">{{ type === 1 ? '您访问的货单已经失效了哦':'您访问的货单暂时无法打开' }}</div>
    </div>
    <div v-if="orginal === 2" class="page_bg">
      <van-image width="8.21rem" height="7.84rem" style="margin-top:1rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/order/shiplistNotWork.png"
      />
      <div class="name">活动结束</div>
      <div class="tip">您访问的活动已经结束啦</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orginal: 1,
      type: null // 1失效 2无法打开
    }
  },
  created() {
    window.document.title = this.$route.query.productModuleName ? this.$route.query.productModuleName : '货单失效'
    this.orginal = this.$route.query.orginal ? parseInt(this.$route.query.orginal) : 1
    this.type = parseInt(this.$route.query.type)
  }
}
</script>

<style>
.name {
  font-size: 0.53rem;
  font-weight: bold;
  color: #333333;
  margin-top: 1rem;
}
.tip {
  font-size: 0.43rem;
  color: #999999;
  margin-top: 0.2rem;
  padding-bottom: 3rem;
}
</style>
